import React from 'react'

import "./Footer.css";

export const Footer = ({ socialMedia, icon, siteName }) => {

    const redirect = () => {
        window.open(socialMedia, "_blank")
    }

    const redirectNorXWest = () => {
        window.open("https://norxwestdesigns.ca", "_blank")
    }

    return (
        <footer>
            <div className="social-media-wrapper">
                <div onClick={redirect} className="icon-container">
                    {icon}
                </div>
            </div>
            <div className="copyright-container">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 7C9.24 7 7 9.24 7 12C7.00148 13.0928 7.3608 14.155 8.02302 15.0243C8.68525 15.8936 9.61393 16.5222 10.6671 16.8138C11.7203 17.1054 12.84 17.0442 13.855 16.6393C14.8701 16.2345 15.7246 15.5084 16.288 14.572L14.573 13.544C14.3031 13.9936 13.9201 14.3646 13.4621 14.6201C13.0041 14.8755 12.4871 15.0064 11.9627 14.9998C11.4384 14.9931 10.9249 14.8491 10.4735 14.5821C10.0222 14.3152 9.64868 13.9345 9.39029 13.4782C9.13189 13.0219 8.99763 12.5058 9.00089 11.9814C9.00414 11.457 9.1448 10.9426 9.40885 10.4895C9.67289 10.0364 10.0511 9.66045 10.5057 9.3991C10.9604 9.13775 11.4756 9.00014 12 9C13.093 9 14.05 9.584 14.574 10.457L16.288 9.427C15.8437 8.68657 15.2153 8.07384 14.4638 7.64851C13.7123 7.22318 12.8635 6.99976 12 7V7Z" fill="black"/>
                </svg>
                <h2>{siteName} 2024</h2>
            </div>
            <div onClick={redirectNorXWest} className="built-by-container">
                <div className="icon-container">
                    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.0607 0.939336C29.4749 0.35355 28.5251 0.35355 27.9393 0.939336L18.3934 10.4853C17.8076 11.0711 17.8076 12.0208 18.3934 12.6066C18.9792 13.1924 19.9289 13.1924 20.5147 12.6066L29 4.12132L37.4853 12.6066C38.0711 13.1924 39.0208 13.1924 39.6066 12.6066C40.1924 12.0208 40.1924 11.0711 39.6066 10.4853L30.0607 0.939336ZM30.5 56L30.5 2H27.5L27.5 56H30.5Z" fill="black"/>
                    <path d="M0.948457 27.7223C0.358156 28.3035 0.350808 29.2532 0.932045 29.8435L10.4038 39.463C10.9851 40.0533 11.9348 40.0607 12.5251 39.4795C13.1154 38.8982 13.1228 37.9485 12.5415 37.3582L4.12214 28.8075L12.6728 20.3881C13.2631 19.8069 13.2705 18.8572 12.6892 18.2669C12.108 17.6766 11.1583 17.6692 10.568 18.2505L0.948457 27.7223ZM56.0109 27.7089L2.01248 27.2912L1.98927 30.2911L55.9877 30.7088L56.0109 27.7089Z" fill="black"/>
                    <path d="M14.6167 46V44.1733L18.0391 43.9004V17.5503L14.6167 17.2773V15.4297H20.5796L38.0483 41.7168L38.1743 41.6748V17.5503L34.605 17.2773V15.4297H43.9692V17.2773L40.5469 17.5503V46H38.1953L20.5376 19.6079L20.4116 19.6499V43.9004L23.981 44.1733V46H14.6167ZM48.6304 46V42.5776H51.5068V46H48.6304Z" fill="black"/>
                    </svg>
                </div>
                <h2>Site Built By: Nor. X West Designs</h2>
            </div>
        </footer>
    )
}
